/* DexScreenerEmbed.module.css */

.container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .mobileContainer {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 90%;
    z-index: 2000;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  
  .embedWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    flex: 1;
  }
  
  .iframe {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 8px;
  }
  
  .closeButton {
    position: absolute;
    top: 5px;
    right: 45px;
    background-color: rgba(51, 51, 51, 0.8);
    color: white;
    border: none;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2001;
    touch-action: manipulation; /* Improves touch response */
    -webkit-tap-highlight-color: transparent; /* Removes highlight on tap for iOS */
  }
  
  .closeButton:hover {
    background-color: rgba(75, 75, 75, 0.8);
  }
  
  .closeButton:active {
    background-color: rgba(100, 100, 100, 0.9);
    transform: scale(0.95); /* Slight press effect */
  }
  
  /* Make the touch target larger than the visual button for better mobile experience */
  .closeButton::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    z-index: -1;
  }
  
  /* Media query for mobile devices */
  @media (max-width: 768px) {
    .mobileContainer {
        padding: 0;
        height: 89%;
        top: 96px;
        overflow-y: hidden;
      }
    
    /* Make the close button more visible and easier to hit on mobile */
    .closeButton {
        top: 5px;
        right: 45px;
      width: 35px;
      height: 35px;
      font-size: 35px;
      background-color: rgba(51, 51, 51, 0.9);
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    }
    
    /* Create even larger invisible touch target on mobile */
    .closeButton::before {
      top: -15px;
      left: -15px;
      right: -15px;
      bottom: -15px;
    }
  }