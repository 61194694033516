.galleryPage {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  min-height: 100dvh;
  padding: 20px;
  background-color: black;
  color: white;
  font-family: Arial, sans-serif;
  background-image: url('/public/bg.png');
  background-size: contain;
  margin-bottom: 0px;
}

.titlesection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.homebutton {
  background-color: white;
    color: black;
    border: none;
    padding: 7px;
    font-size: 1.25dvw;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 20px;
    position: fixed;
    top: 1%;
    left: 1%;
    font-weight: 600;
    display: block;  /* Makes the button behave like a block element */
    margin-left: auto;
    margin-right: auto; /* Centers it horizontally */
    text-decoration: none;
    opacity: .75;
}

.galleryTitle {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
  flex-grow: 1;
}

.galleryContent {
  display: flex;
  flex: 1;
  gap: 20px;
  height: 100%;
  overflow-y: scroll;
}

.filterPanel {
  width: 250px;
  padding: 20px;
  background-color: #1a1a1a;
  border-radius: 10px;
  overflow-y: auto;
  height: 100%;
  position: sticky;
  top: 20px;
}

.filterTitle {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.filterGroup {
  margin-bottom: 1rem;
}

.filterCategoryTitle {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.filterCheckbox {
  display: block;
  margin-bottom: 0.25rem;
}

.filterLabel {
  margin-left: 0.5rem;
}

.nftGridContainer {
  flex: 1;
  overflow: scroll;
}

.nftGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.nftCard {
  background-color: #1a1a1a;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s;
}

.nftCard:hover {
  transform: scale(1.05);
}

.nftImage {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.nftName {
  padding: 10px;
  font-size: 1rem;
  text-align: center;
}

.modalOverlay {
  display: flex;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  align-items: center;
  justify-content: center;
}

.modalContent {
  background-color: #121212;
  width: 80%;
  height: 80%;
  max-width: 1200px;
  max-height: none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  padding: 20px;
  color: white;
}

.closeButton {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 30px;
  color: #ffffff;
  cursor: pointer;
  z-index: 2;
}

.modalTitle {
  font-size: 24px;
  font-weight: bold;
  color: white;
  margin-top: 20px;
  text-align: center;
}

.modalBody {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.nftContentWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.modalImageContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
}

.modalImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.modalInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
}

.traitsTitle {
  font-size: 20px;
  margin-bottom: 15px;
  color: white;
}

.traitsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-bottom: 20px;
}

.traitItem {
  background-color: #2a2a2a;
  padding: 10px;
  border-radius: 5px;
  color: white;
}

.inscriptionInfo {
  text-align: center;
  margin-top: 20px;
}

.inscriptionInfo p {
  color: #cccccc;
  margin-bottom: 10px;
}

.ordiscanButton {
  display: inline-block;
  background-color: #0077cc;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.ordiscanButton:hover {
  background-color: #005fa3;
}

.loadingState,
.errorState {
  text-align: center;
  padding: 20px;
  font-size: 1.2rem;
}

.mobileFilterToggle {
  display: none;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000000; /* Adjust this to match your site's background */
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #ffffff;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loadingScreen {
  color: #ffffff; /* Adjust this to match your site's text color */
  font-size: 24px;
  text-align: center;
}

@media (max-width: 768px) {
  .galleryContent {
    flex-direction: column;
  }


  .filterPanel {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }

  .showMobileFilter {
    transform: translateX(0);
  }

  .nftGrid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); 
  }

  .nftGridContainer {
    flex: 1;
    overflow: scroll;
  }

  .modalContent {
    width: 90%;
    height: 90%;
    overflow-y: auto;
    padding: 40px 20px 20px;
  }

  .closeButton {
    top: 10px;
    right: 10px;
    font-size: 36px;
    z-index: 3;
  }

  .modalBody {
    flex-direction: column;
    margin-top: 0;
    height: auto;
  }

  .nftContentWrapper {
    flex-direction: column;
    align-items: center;
  }

  .modalImageContainer {
    padding-right: 0;
    margin-bottom: 20px;
    width: 100%;
  }

  .modalImage {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 40vh;
    object-fit: contain;
    margin-top: 20px;
  }

  .titlesection {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .galleryTitle {
    font-size: 1.75rem;
    font-weight: bold;
    margin-bottom: 4dvw;
    text-align: center;
    flex-grow: 1;
    margin-top: 7dvw;
  }

  .homebutton {
    background-color: white;
    color: black;
    border: none;
    padding: 7px;
    font-size: 3dvw;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 20px;
    position: absolute;
    font-weight: 600;
    display: block;  /* Makes the button behave like a block element */
    margin-left: auto;
    margin-right: auto; /* Centers it horizontally */
    text-decoration: none;
  }

  .modalTitle {
    font-size: 24px;
    margin: 10px 0;
    text-align: center;
  }

  .modalInfo {
    padding-left: 0;
    width: 100%;
  }

  .traitsGrid {
    grid-template-columns: 1fr;
  }

  .mobileFilterToggle {
    display: block;
    position: fixed;
    bottom: 10px;
    right: 25px;
    z-index: 1001;
    background-color: rgb(0, 0, 0);
    opacity: .9;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 20px;
    cursor: pointer;
    font-weight: 600;
  }

  .spinner {
    width: 30px;
    height: 30px;
  }

  .loadingScreen {
    font-size: 20px;
  }

  .galleryPage {
    background-image: url('/public/bg2.png');
    background-size: cover;
    
  }
}