
.watchlistContainer {
  border-radius: 1dvw;  /* Matches the central chat panel */
  position: relative;
  min-height: 85dvh;
  max-height: 85dvh;
  background-color: rgba(23, 23, 23, 0.7);
  backdrop-filter: blur(5px);
  transition: width 0.3s ease;
  z-index: 1000;
  border: 2px solid rgba(255, 255, 255, 0.1);
  padding: 0.5dvw;  /* Adjust padding if necessary */
  width: 15dvw;
  overflow-y: scroll;
}

.libraryContainer, .watchlistContainer {
  padding: 0.5dvw;  /* Consistent padding */
}

.tradeChartModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.tradeChartModal .modalContent {
  background-color: #1a1a1a;
  border-radius: 8px;
  padding: 24px;
  width: 90%;
  max-width: 1200px;
  height: 80vh;
  position: relative;
  display: flex;
  flex-direction: column;
}

.tradeChartModal .modalTitle {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 20px;
  text-align: center;
}

.tradeChartModal .closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  z-index: 1;
}

.watchlistContainer:hover {
  box-shadow: 0 0 40px rgba(179, 179, 179, 0.5);
}

.categoryHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.05);
  margin-bottom: 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1dvw;
  line-height: 1;
}

.collapseIcon {
  width: 16px; /* Fixed width */
  height: 16px; /* Fixed height */
  display: flex; /* Ensures centering within its own box */
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease; /* Smooth rotation if needed */
}

.collapseIcon2 {
  width: 8px; /* Fixed width */
  height: 8px; /* Fixed height */
  display: flex; /* Ensures centering within its own box */
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  transition: transform 0.2s ease; /* Smooth rotation if needed */
}

.collapseIcon2.collapsed {
  transform: rotate(0deg); /* Right-facing or initial state (►) */
}

.collapseIcon2:not(.collapsed) {
  transform: rotate(0deg); /* Downward-facing (▼) when expanded */
}

.collapseIcon.collapsed {
  transform: rotate(0deg); /* Right-facing or initial state (►) */
}

.collapseIcon:not(.collapsed) {
  transform: rotate(0deg); /* Downward-facing (▼) when expanded */
}
.chainHeader {
  cursor: pointer;
  padding: .15dvw;
  margin-bottom: 0px;
  border-radius: 4px;
  font-size: .9dvw;
  margin-top: 0dvh;
  margin-left: 0dvw;
}

.categoryHeader2 {
  height: 95%;
    display: flex;
    flex-direction: column;
    padding: .1dvw;
    font-size: 1dvw;
    overflow-y: auto;
}


.categoryHeader:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.categoryContent {
  margin-bottom: 16px;
}

.categoryTitle {
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
}

.collapseIcon {
  transform: rotate(0deg);
  transition: transform 0.2s ease;
}

.collapseIcon.collapsed {
  transform: rotate(-90deg);
}

.expanded {
  width: 15dvw;
}

.collapsed {
  width: 30px;
  height: 0vh;
}

.toggleButton {
  position: absolute;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 40px;
  background-color: rgba(81, 81, 81, 0.448);
  border: none;
  color: white;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  border-radius: 20px 20px 20px 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-right: none;
}

.toggleButton:hover {
  background-color: rgba(30, 30, 30, 0.95);
}

.watchlistContent {
  height: 95%;
  display: flex;
  flex-direction: column;
  padding: .1dvw;
  font-size: 1dvw;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.header3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  font-size: 1dvw;
  font-weight: 600;
  color: white;
  margin: 0;
  background: none !important;
}

.headerButtons {
  display: flex;
  flex-direction: row;
}

.addModal {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  justify-content: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  
}

.selector {
  background-color: #333;
  color: white;
}

.addModal,h3 {
  color: white;
  font-size: 1.2dvw;
  text-align: center;
}

.addoption {
  color: white;
}

.addButton {
  background-color: rgba(255, 255, 255, 0.05);  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  margin-top: 10px;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.addButton:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.mobileOnly {
  display: none;
}



.itemsList {
  flex: 1;
  overflow-y: auto;
  padding: .1dvw;
}

.chainTitle {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.05);
  margin-bottom: 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: .85dvw;
  line-height: 1;
}

.chainItems {
  padding: .1dvw;
  margin-bottom: .1dvw;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: .9dvw;
}

.item {
  background-color: rgba(31, 31, 31, 0.6);
    border-radius: 6px;
    padding: .5dvw;
    margin-bottom: 1dvh;
    cursor: pointer;
    transition: all 0.2s ease;
    border: 1px solid rgba(255, 255, 255, 0.1);
    font-size: .9dvw;
}

.item:hover {
  background-color: rgba(45, 45, 45, 0.6);
  transform: translateX(-4px);
}

.itemHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.itemName {
  display: block;
  margin-right: 24px; /* Add space for the remove button */
  font-size: .9dvw;
}

.itemPrice {
  color: #10b981;
  font-family: monospace;
  font-size: .85dvw;
  font-weight: 600;
  white-space: nowrap;  
  display: block;  
}

.itemContent {
  position: relative;  /* Add this to establish positioning context */
  padding: 0px;      /* Add some padding to prevent overlap */
  width: 100%;
}

.itemType {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}


.itemMeta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2px;
}

.removeButton {
  position: absolute;
  top: -10px;          /* Adjust these values to fine-tune position */
  right: -5px;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.25dvw;
  cursor: pointer;
  padding: 4px 8px;
  line-height: 1;
}

.removeButton:hover {
  color: rgba(255, 255, 255, 0.8);
}

.modal {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
}

.modalContent {
  background-color: rgba(31, 31, 31, 0.95);
  border-radius: 8px;
  padding: 24px;
  width: 90%;
  max-width: 400px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}

.modalTitle {
  font-size: 1.25rem;
  font-weight: 600;
  color: white;
  margin-bottom: 20px;
}

.formGroup {
  margin-bottom: 16px;
}

.input,
.select {
  width: 100%;
  padding: 8px 12px;
  background-color: rgba(45, 45, 45, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  color: white;
  margin-top: 4px;
}

.input:focus,
.select:focus {
  outline: none;
  border-color: rgba(59, 130, 246, 0.5);
}

.buttonGroup {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 24px;
}

.button {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
  font-size: 0.875rem;
}

.buttonCancel {
  background-color: #333;
  color: white;
  border: none;
  padding: 0.5dvw 1dvw;
  cursor: pointer;
  border-radius: 4.95dvw;
  font-size: 0.8dvw;
  transition: background-color 0.2s ease;
  z-index: 200;
}

.buttonCancel:hover {
  background-color: rgba(75, 85, 99, 1);
}

.buttonSubmit {
  background-color: #333;
  color: white;
  border: none;
  padding: 0.5dvw 1dvw;
  cursor: pointer;
  border-radius: 4.95dvw;
  font-size: 0.8dvw;
  transition: background-color 0.2s ease;
  z-index: 200;
}

.buttonSubmit:hover {
  background-color: rgba(59, 130, 246, 1);
}

/* Scrollbar styles */
.itemsList::-webkit-scrollbar {
  width: 1px;
}

.itemsList::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

.itemsList::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
}

.itemsList::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}


@media (max-width: 768px) {
  .watchlistContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100dvw;
    height: 100dvh;
    min-height: 100dvh;
    z-index: 1001;
    background-color: rgba(23, 23, 23, 0.7);
    backdrop-filter: blur(5px);
    border-radius: 0;
    padding: 0;
    overflow-y: auto;
  }

  .watchlistContent {
    height: 100%;
    width: 100%;
  }

  .categoryContent {
    width: 100%;
  }

  .title {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #1a1a1a;
    font-size: 5dvw;
}

.chainHeader {
  cursor: pointer;
  padding: 0dvw;
  margin-bottom: 0px;
  border-radius: 0dvw;
  margin-top: .75dvh;
  margin-left: 0dvw;
}

.categoryTitle {
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 4dvw;
}

.categoryHeader2 {
  height: 95%;
    display: flex;
    flex-direction: column;
    padding: .1dvw;
    font-size: 5dvw;
    overflow-y: auto;
}

.chainTitle {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.05);
  margin-bottom: 8px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 0dvw;
  font-size: 3dvw;
  line-height: 1;
  width: 100%;
}


.item {
  background-color: rgba(31, 31, 31, 0.6);
  border-radius: 6px;
  padding: 0.5dvw;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 0.9dvw;
  width: 100%; /* Takes full column width */
  margin: 0; /* No unintended spacing */
  text-align: center; /* Ensures text inside is centered */
}

.itemName {
  display: block;
  margin-right: 24px; /* Add space for the remove button */
  font-size: 3dvw;
  text-align: left;
}

.itemPrice {
  color: #10b981;
  font-family: monospace;
  font-size: 3dvw;
  font-weight: 600;
  white-space: nowrap;  
  display: block;  
  text-align: left;
  width: 100%;
}

.itemsList {
  display: flex;
 flex-direction: column;
 flex: 1;
 align-items: space-between;
 justify-content: space-between;
 margin: auto;
 width: 100%;
 
}

.chainItems {
  display: grid;
  grid-template-columns: repeat(3, minmax(120px, 1fr)); /* Ensures responsive max 3 columns */
  gap: 0.5rem; /* Small space between items */
  padding: 0; /* No extra padding */
  margin: 0 auto; /* Ensures it's centered within the parent */
  width: fit-content; /* Full width to keep items balanced */
  justify-content: center; /* Ensures grid content is centered */
}

.itemContent {
 
  padding: 0px;      /* Add some padding to prevent overlap */
  width: 100%;
  display: flex;
  flex-direction: column;
}

.removeButton {
  position: absolute;
  margin-top: 5px;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.7); /* Subtle color */
  font-size: 4dvw; /* Adjust button size */
  cursor: pointer;
  padding: 4px 8px;
  line-height: 1;
}

.removeButton:hover {
  color: rgba(255, 255, 255, 1); /* Highlights on hover */
}

.mobileOnly {
  display: flex;
  background-color: rgba(255, 255, 255, 0.05);  color: white;
  padding: 6px 12px;
  border-radius: 2dvw;
  margin-top: 1dvw;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
  width: 30dvw;
  text-align: center;
  justify-content: center;
}

.addButton {
  background-color: rgba(255, 255, 255, 0.05);  color: white;
  padding: 6px 12px;
  border-radius: 2dvw;
  margin-top: 1dvw;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
  width: 30dvw;
  text-align: center;
  justify-content: center;
}

.headerButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 75%;
  margin-top: 0dvw;
}

.addModal {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  justify-content: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);  color: white;
}

.addModal,h3 {
  color: white;
  font-size: 7dvw;
  text-align: center;
}

}