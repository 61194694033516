.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
  }
  
  .modal {
    background-color: #2a2a2a;
    border-radius: 16px;
    padding: 32px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: modalAppear 0.3s ease-out;
  }
  
  @keyframes modalAppear {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .modal h2 {
    margin-top: 0;
    font-size: 28px;
    margin-bottom: 24px;
    color: #f6851b;
    text-align: center;
  }
  
  .tabContainer {
    display: flex;
    width: 100%;
    margin-bottom: 24px;
    background-color: #222;
    border-radius: 8px;
    padding: 4px;
    position: relative;
  }
  
  .tabButton {
    flex: 1;
    background: none;
    border: none;
    padding: 12px 20px;
    font-size: 16px;
    color: #bbb;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    z-index: 2;
    border-radius: 6px;
  }
  
  .tabButton:hover {
    color: white;
  }
  
  .activeTab {
    color: #222;
    font-weight: 600;
  }
  
  /* This creates the sliding background effect */
  .tabContainer::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: calc(50% - 4px);
    height: calc(100% - 8px);
    background-color: #f6851b;
    border-radius: 6px;
    transition: transform 0.3s ease;
    z-index: 1;
  }
  
  .tabContainer.walletActive::before {
    transform: translateX(calc(100% - 0px));
  }
  
  .tabContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 12px;
  }
  
  .note {
    font-size: 14px;
    color: #aaa;
    margin-bottom: 24px;
    text-align: center;
    padding: 0 10px;
    line-height: 1.5;
  }
  
  .discordButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 24px;
    border-radius: 10px;
    background-color: #5865F2;
    color: white;
    border: none;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
    max-width: 320px;
    margin: 12px 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .discordButton:hover {
    background-color: #4752c4;
    transform: translateY(-2px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
  
  .discordButton:active {
    transform: translateY(0);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  }
  
  .discordButton svg {
    margin-right: 12px;
  }
  
  .walletButtons {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 320px;
    gap: 14px;
    margin-top: 8px;
  }
  
  .walletButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 24px;
    border-radius: 10px;
    background-color: #3a3a3a;
    color: white;
    border: none;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .walletButton:hover {
    background-color: #4a4a4a;
    transform: translateY(-2px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
  
  .walletButton:active {
    transform: translateY(0);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  }
  
  /* Unique colors for each wallet button */
  .walletButton.xverse {
    background-color: #2c3e50;
  }
  
  .walletButton.xverse:hover {
    background-color: #34495e;
  }
  
  .walletButton.phantom {
    background-color: #4b0082;
  }
  
  .walletButton.phantom:hover {
    background-color: #5d0a9f;
  }
  
  .walletButton.magiceden {
    background-color: #800080;
  }
  
  .walletButton.magiceden:hover {
    background-color: #9a009a;
  }
  
  .closeButton {
    margin-top: 28px;
    padding: 12px 24px;
    border-radius: 8px;
    background-color: transparent;
    color: #aaa;
    border: 1px solid #444;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .closeButton:hover {
    color: white;
    border-color: white;
    background-color: rgba(255, 255, 255, 0.05);
  }
  
  .error {
    background-color: rgba(255, 0, 0, 0.15);
    border-left: 3px solid #ff3333;
    padding: 12px 16px;
    margin-bottom: 24px;
    border-radius: 0 6px 6px 0;
    width: 100%;
  }
  
  .error p {
    color: #ff3333;
    margin: 0;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .modal {
      padding: 24px 16px;
      width: 92%;
      max-width: none;
      margin: 16px;
      border-radius: 12px;
    }
    
    .modal h2 {
      font-size: 24px;
      margin-bottom: 20px;
    }
    
    .tabButton {
      padding: 10px 15px;
      font-size: 15px;
    }
    
    .walletButtons, 
    .discordButton {
      width: 100%;
      max-width: none;
    }
    
    .note {
      font-size: 13px;
      margin-bottom: 20px;
    }
    
    .closeButton {
      margin-top: 24px;
    }
  }