.fetchModeActive {
  background-color: rgba(40, 120, 200, 0.1);
  border-left: 3px solid #3498db;
  padding-left: 10px;
}

.fetchPrompt {
  color: #3498db;
  font-weight: 600;
}

.messagesContainer a {
  color: orange !important; /* Green */
  font-weight: 600;
}

.messagesContainer a:hover {
  color: #27ae60 !important; /* Darker green on hover */
}


/* Optional: Add a styling for the fetch results */
.fetchResult {
  background-color: rgba(52, 152, 219, 0.05);
  border-radius: 8px;
  padding: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
  border-left: 3px solid #3498db;
}

/* Style for the Go Fetch button in dropdown */
.dropdownItem.fetchButton {
  background-color: rgba(52, 152, 219, 0.2);
}

.dropdownItem.fetchButton:hover {
  background-color: rgba(52, 152, 219, 0.4);
}

.bitcoinPriceContainer {
  display: flex;
  align-items: center;
  background-color: rgba(51, 51, 51, 0.7);
  border-radius: 4.95dvw;
  padding: 0.5dvw 1dvw;
  font-size: 0.8dvw;
  margin: 1dvw;
  color: white;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.bitcoinPriceContainer:hover {
  background-color: rgba(70, 70, 70, 0.8);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.bitcoinPrice {
  color: #10b981;
  font-weight: 600;
  margin-left: 4px;
  transition: color 0.5s ease;
}

.priceUp {
  color: #10b981; /* Green */
  animation: pulsate 2s ease-out;
}

.priceDown {
  color: #ff6b6b; /* Red */
  animation: pulsate 2s ease-out;
}

.priceStable {
  color: #ffffff; /* Gold/Yellow */
}

@keyframes pulsate {
  0% { opacity: 0.7; }
  50% { opacity: 1; }
  100% { opacity: 0.7; }
}

.loadingPrice {
  color: #d1d1d1;
  font-style: italic;
  margin-left: 4px;
}

.errorPrice {
  color: #ff6b6b;
  margin-left: 4px;
}
.pageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100dvh;
    padding: 1dvw;
    background: url('./desktopbg.jpg') no-repeat center center fixed, linear-gradient(45deg, #000000, #1a1a1a, #333333);
    background-size: cover;
  }

  .clarificationRequest {
    background-color: #2c3e50;
    border-left: 4px solid #3498db;
    padding: 12px 16px;
    margin: 10px 0;
    border-radius: 6px;
    animation: fadeIn 0.5s ease-out;
  }
  
  .clarificationRequest strong {
    color: #3498db;
    display: block;
    margin-bottom: 6px;
    font-size: 1.1em;
  }
  
  .clarificationNote {
    color: #f0f0f0;
    background-color: rgba(52, 152, 219, 0.2);
    border-left: 3px solid #3498db;
    padding: 8px 12px;
    margin: 8px 0;
    border-radius: 4px;
    font-size: 0.9em;
  }

  .mainContent {
    display: flex;
    flex-direction: row;
    gap: 1dvw;
    width: 100%;
    max-width: 100dvw;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .imageModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    position: relative;
    max-width: 100%;
    max-height: 100%;
  }
  
  .enlargedImage {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    background-color: black;
  }
  
  .imageContainer {
    margin-top: 10px;
    max-width: 100%;
    max-height: 80%;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  
  .generatedImage {
    max-width: 100%;
    max-height: 512px;
    border-radius: 8px;
    object-fit: contain;
  }
  
  .agentModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
  }
  
  .agentModalContent {
    width: 90dvw;
    max-width: 100dvw;
    height: 90dvh;
    border-radius: 0.4dvw;
    padding: 1dvw;
    background: #1a1a1a;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .closeButton {
    position: absolute;
    top: 0dvw;
    right: .5dvw;
    background: none;
    border: none;
    font-size: 2.5dvw;
    cursor: pointer;
    color: white;
  }
  
  .agentMessages {
    flex: 1;
    overflow-y: auto;
    margin: 1dvh 0;
    padding: 0.5dvw;
    color: white;
  }
  
  .saveButton {
    font-size: 1dvw;
    font-weight: 600;
    background-color: #494949;
    color: white;
    padding: 1dvw;
  }
  
  .agentA, .agentB {
    margin: 0.5dvh 0;
    padding: 0.5dvw;
    border-radius: 1dvw;
  }
  
  .agentA {
    background: #2a2a2a;
  }
  
  .agentB {
    background: #4d4d4d;
    margin-left: 3dvw;
    margin-top: 1dvw;
  }
  
  .messageContent {
    margin-top: 0.25dvh;
    white-space: pre-wrap;
  }
  
  .agentInput {
    display: flex;
    gap: 10px;
    padding: 0.5dvw;
    background: #2a2a2a;
    border-radius: 0.2dvw;
  }
  
  .agentInput input {
    flex: 1;
    background: #333;
    border: none;
    padding: 0.4dvw;
    color: white;
    border-radius: 0.2dvw;
  }
  
  .agentInput button {
    padding: 0.4dvw 0.8dvw;
    background: #444;
    border: none;
    color: white;
    border-radius: 0.2dvw;
    cursor: pointer;
  }
  
  .agentInput button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .connectPrompt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .connectButton {
    background-color: white;
    color: black;
    border: none;
    padding: 0.5dvw 1dvw;
    font-size: 2dvw;
    border-radius: 0.25dvw;
    cursor: pointer;
    transition: background-color 0.3s ease;
    opacity: .75;
    font-weight: 600;
    margin-top: 2dvh;
  }
  
  .connectButton2 {
    color: white;
    border: none;
    padding: 0.5dvw 1dvw;
    font-size: 1.25dvw;
    border-radius: 0.25dvw;
    cursor: pointer;
    transition: background-color 0.3s ease;
    opacity: .75;
    font-weight: 600;
    margin-top: 2dvh;
  }
  
  .dexScreenerContainer {
    width: 100dvw;
    max-width: 40dvw;
    margin: 1dvh auto;
    margin-right: 1dvh;
    background-color: rgba(51, 51, 51, 0.8);
    border-radius: 0.5dvw;
    overflow: hidden;
  }
  
  .chatContainer {
    flex: 3;
    background-color: rgba(23, 23, 23, 0.7);
    backdrop-filter: blur(5px);
    border-radius: 1dvw;
    box-shadow: 0 0 30px rgba(179, 179, 179, 0.3);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
    height: 90%;
  }

  .collapsed {
    display: none;
  }

  .watchlistContainer:hidden, .libraryContainer:hidden {
    display: none;
  }
  
  
  .chatContainer:hover {
    box-shadow: 0 0 40px rgba(179, 179, 179, 0.5);
  }
  
  .buttonContainer {
    position: relative;
    width: 100%;
    background-color: rgba(23, 23, 23, 0.7);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .dropdownMenu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: rgba(31, 31, 31, 0.9);
    border-radius: 0.4dvw;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.7);
   
    z-index: 1001;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 16dvw;
    min-height: 70dvh;
    place-items: center; /* This centers items both horizontally and vertically */
  }
  
  .dropdownItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(60, 60, 60, 0.7);
    border: grey;
    border-style: solid;
    border-radius: 4.95dvw;
    border-width: 1px;
    color: white;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 1dvw;
    font-size: 1dvw;
    height: 4dvw;
    width: 7dvw;
    padding: 1dvw;
    overflow-y: scroll;
  }
  
  .dropdownItem:hover {
    background-color: rgba(80, 80, 80, 0.8);
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  /* Optional: add this to make sure the text and icons inside are centered properly */
  .dropdownItem > * {
    margin: 0.2dvw 0;
  }
  
  .dropdownItem:hover {
    background-color: rgba(255, 255, 255, 0.357);
  }
  
  .functionBtn {
    background-color: #333;
    color: white;
    border: none;
    padding: 0.5dvw 1dvw;
    cursor: pointer;
    border-radius: 4.95dvw;
    font-size: 0.8dvw;
    margin-right: 0dvw;
    margin: 1dvw;
    transition: background-color 0.2s ease;
    z-index: 200;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .headerbtn {
    color: white;
    border: none;
    padding: 0.5dvw 1dvw;
    cursor: pointer;
    border-radius: 4.95dvw;
    font-size: 1dvw;
    margin: 1dvw;
    transition: background-color 0.2s ease;
  }
  
  .functionBtn:hover {
    background-color: rgba(255, 255, 255, 0.357);
  }
  
  .messagesContainer {
    flex: 1;
    overflow-y: auto;
    padding: 1dvw;
    scroll-behavior: smooth;
    margin: 0 2dvw 0 2dvw;
  }
  
  .message {
    padding: 0.75dvw;
    margin: 0.5dvh 0;
    border-radius: 0.75dvw;
    max-width: 100%;
    animation: fadeIn 0.5s ease-out;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    height: fit-content;
    font-size: 1.1dvw;
    margin-top: .5dvw;
  }
  
  .message:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  .agent-a {
    background-color: #1a1a1a;
    align-self: flex-start;
    border-bottom-left-radius: 0;
  }

  
  .chartToolContainer {
    width: 100dvw;
    max-width: 40dvw;
    margin-top: 3.25dvh;
    margin-bottom: 1dvh;
  }
  
  .popupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popupContent {
    background-color: #1a1a1a;
    padding: 1dvw;
    border-radius: 0.5dvw;
    width: 15dvw;
  }
  
  .popupContent h2 {
    color: #ffffff;
    margin-bottom: 1dvh;
  }
  
  .popupContent form {
    display: flex;
    flex-direction: column;
  }
  
  .popupContent input,
  .popupContent select {
    margin-bottom: 0.5dvh;
    padding: 0.5dvw;
    border: 1px solid #333;
    background-color: #2a2a2a;
    color: #ffffff;
    border-radius: 0.25dvw;
  }
  
  .popupContent button {
    padding: 0.5dvw;
    margin-top: 0.5dvh;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 0.25dvw;
    cursor: pointer;
  }
  
  .tokenDataInput {
    display: flex;
    align-items: center;
    margin-left: 0.5dvh;
    justify-content: center;
  }
  
  .tokenDataInput input {
    padding: 0.25dvw 0.5dvw;
    margin-right: 0.25dvh;
    border: 1px solid #4CAF50;
    border-radius: 0.2dvw;
    background-color: #2a2a2a;
    color: #ffffff;
  }
  
  .tokenDataInput input::placeholder {
    color: #aaaaaa;
  }
  
  .popupContent button:hover {
    background-color: #45a049;
  }
  
  .popupContent button[type="button"] {
    background-color: #f44336;
  }
  
  .popupContent button[type="button"]:hover {
    background-color: #d32f2f;
  }
  
  .contentContainer {
    display: flex;
    height: 70dvh;
    overflow-y: scroll;
  }

  .collapsed {
    flex: 0;
    width: 0;
    overflow: hidden;
  }
  
  .agent-b {
    background-color: #333333;
    align-self: flex-end;
    border-bottom-right-radius: 0;
  }
  
  .user {
    background-color: #85858580;
    color: #d9d9d9;
    align-self: flex-end;
    border: .75dvw;
  }
  
  .inputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1dvw;
    border-top: 2px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 0dvw;
  }
  
  .messageInput {
    flex-grow: 1;
    padding: 0.75dvw 1dvw;
    border: none;
    border-radius: 1.5dvw;
    background-color: rgba(255, 255, 255, 0.1);
    color: #d9d9d9;
    font-size: 1dvw;
    margin-right: 0.5dvw;
    resize: none !important; /* Force disable resizing */
    overflow: hidden; /* Prevent scrollbar from appearing */
    height: auto; /* Adjust as needed */
    min-height: 10dvh; /* Ensures it has a proper height */
    max-height: 100px; /* Set a max height to prevent expansion */
    overflow-y: scroll;
  }
  
  .messageInput:focus {
    outline: none;
    box-shadow: 0 0 0 2px #b3b3b3;
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .actionButton {
    padding: 0.75dvw 1.25dvw;
    background-color: #b3b3b3;
    color: #1a1a1a;
    border: none;
    border-radius: 1.5dvw;
    cursor: pointer;
    font-weight: bold;
    margin-left: 0.25dvw;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .actionButton:hover {
    background-color: #999999;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(179, 179, 179, 0.4);
  }
  
  .actionButton:active {
    transform: translateY(1px);
  }
  
  .newChatBtn {
    background-color: rgb(70, 70, 70);
    margin-left: 0.5dvh;
    padding: 0.75dvw 1.25dvw;
    color: white;
    border: none;
    border-radius: 4.95dvw;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .newChatBtn:hover {
    background-color: #45a049;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(179, 179, 179, 0.4);
  }
  
  .newChatBtn:active {
    transform: translateY(1px);
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 3dvw;
    height: 1.7dvh;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .reviewerToggle {
    display: flex;
    align-items: center;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 1.7dvw;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 1.3dvh;
    width: 1.3dvw;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #b3b3b3;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  .toggleLabel {
    margin-left: 0.5dvh;
    color: #d9d9d9;
  }
  
  .errorMessage {
    background-color: #ffdddd;
    color: #ff0000;
    padding: 0.5dvw;
    margin: 0.5dvh 0;
    border-radius: 0.25dvw;
    border: 1px solid #ff0000;
  }
  
  .thinking {
    background-color: #2a2a2a;
    color: #ffffff;
  }
  
  .dotAnimation {
    display: inline-block;
  }
  
  .dotAnimation span {
    animation: dotFade 1.4s infinite;
    opacity: 0;
  }
  
  .dotAnimation span:nth-child(1) {
    animation-delay: 0.0s;
  }
  
  .dotAnimation span:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .dotAnimation span:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes dotFade {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  .codeBlock {
    background-color: #1e1e1e;
    border-radius: 0.2dvw;
    margin: 0.5dvh 0;
    overflow: hidden;
  }
  
  .codeHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2a2a2a;
    padding: 0.25dvw 0.5dvw;
  }
  
  .language {
    color: #a9a9a9;
    font-size: 0.72dvw;
  }
  
  .copyBtn {
    background-color: #3a3a3a;
    color: #ffffff;
    border: none;
    padding: 0.25dvw 0.5dvw;
    border-radius: 0.15dvw;
    cursor: pointer;
    font-size: 0.72dvw;
    transition: background-color 0.3s;
  }
  
  .copyBtn:hover {
    background-color: #4a4a4a;
  }
  
  .codeBlock pre {
    margin: 0;
    padding: 0.5dvw;
    overflow-x: auto;
  }

  .mobileMenuContainer {
    display: none; /* Hidden on desktop */
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1001;
  }

  .mobileMenuButton {
    background: #333;
    color: white;
    border: none;
    padding: 10px;
    font-size: 24px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .mobileMenu {
    position: fixed;
    top: 50px;
    right: 10px;
    background: #444;
    padding: 10px;
    border-radius: 5px;
    z-index: 1001;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .mobileMenu button {
    background: #555;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .mobileMenu button:hover {
    background: #666;
  }

  .imageButtonsContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    width: 25dvw;
    align-items: center;
  }

  .saveContainer {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 2dvw;
  }
  
  .saveImageButton{
    background-color: #333;
    color: white;
    border: none;
    padding: 0.5dvw 1dvw;
    cursor: pointer;
    border-radius: 4.95dvw;
    font-size: 1dvw;
    margin-right: 0dvw;
    margin: 1dvw;
    transition: background-color 0.2s ease;
    z-index: 200;
  }
  
  .saveImageButton:hover {
    background-color: #0056b3;
  }
  
  .viewImageButton {
    background-color: #555;
  }
  
  .viewImageButton:hover {
    background-color: #333;
  }
  
  .codeBlock code {
    font-family: 'Courier New', Courier, monospace;
    font-size: .8dvw;
    color: #ffffff;
  }
  
  @media (max-width: 768px) {
    .watchlistContainer, .libraryContainer {
        width: 100%;
        max-width: none;
        border-radius: 0;
      }
      .chatContainer {
        flex: 3;
        background-color: rgba(23, 23, 23, 0.7);
        backdrop-filter: blur(5px);
        border-radius: 0;
        box-shadow: 0 0 30px rgba(179, 179, 179, 0.3);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        transition: all 0.3s ease;
        height: 100% !important;
        min-height: 100% !important;
        margin: 0 !important;
        width: 100%;
      }
    .mobileMenuContainer {
      display: block;
      position: fixed;
      top: 10px;
      left: 10px;
      z-index: 1002;
    }
    .mobileMenuButton {
      background-color: #333;
      color: white;
      border: none;
      padding: 10px;
      font-size: 24px;
      border-radius: 5px;
    }
    .mobileMenu {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 1001;
      display: flex;
      flex-direction: column;
      padding: 20px;
    }
    .mobileMenu button {
      background-color: #333;
      color: white;
      border: none;
      padding: 15px;
      margin: 5px 0;
      font-size: 18px;
      border-radius: 5px;
    }
    .mainContent {
        display: flex;
        flex-direction: column;
        gap: 1dvw;
        width: 100%;
        max-width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
    .agentModalContent {
      width: 100vw;
      height: 100vh;
      max-width: none;
      border-radius: 0;
    }
    .popupContent {
      width: 95vw;
      height: 90vh;
      max-width: none;
      max-height: none;
    }
    .actionButton {
      min-height: 44px;
      font-size: 16px;
      padding: 10px;
    }
    .message {
      font-size: 16px;
      border-radius: 4dvw;
      padding: 2dvw;
      margin-top: 2dvw;
    }
    .inputContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 2dvw;
        border-top: 2px solid rgba(255, 255, 255, 0.1);
        margin-bottom: 0dvw;
  
      }

      .bitcoinPriceContainer {
        font-size: 4dvw;
        padding: 0.5dvw 2dvw;
      }
      .functionBtn {
        font-size: 4dvw;
        padding: 0.5dvw 2dvw;
      }
      .messageInput {
        width: 60%;
        font-size: 16px; /* Keep at 16px to prevent zoom */
        padding: 2dvw;
        height: auto;
        margin-bottom: 0dvw;
        -webkit-text-size-adjust: 100%; 
      }
  .actionButton {
    padding: 8px;
    font-size: 12px;
  }
   
    .headerbtn {
      font-size: 18px;
    }
   
    .contentContainer {
      height: 100%;
    }
    .dexScreenerContainer {
      width: 100vw;
      max-width: none;
    }
    .chartToolContainer {
      width: 100vw;
      max-width: none;
    }

    .pageContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: -webkit-fill-available;
        height: 100dvh;
        width: 100dvw;
        margin: 0;
        padding: 0;
        padding-bottom: env(safe-area-inset-bottom); /* Fixes iOS bottom gap */
        overflow-x: hidden;
        overflow-y: hidden;
        background: url('./mobilebgl.png') no-repeat center center fixed, linear-gradient(45deg, #000000, #1a1a1a, #333333);
        background-size: cover;
      }
      
      html, body {
        margin: 0;
        padding: 0;
        height: 100%;
        overflow: hidden;
      }

      .connectButton {
        background-color: white;
        color: black;
        border: none;
        padding: 0.5dvw 1dvw;
        font-size: 10dvw;
        border-radius: 0.25dvw;
        cursor: pointer;
        transition: background-color 0.3s ease;
        opacity: .75;
        font-weight: 600;
        margin-top: 2dvh;
      }

      .saveButton {
        font-size: 5dvw;
        font-weight: 600;
        background-color: #494949;
        color: white;
        padding: 1dvw;
      }

      .closeButton {
        position: absolute;
        top: 1.85dvw;
        right: 1dvw;
        background: none;
        border: none;
        font-size: 10dvw;
        cursor: pointer;
        color: white;
      }
      
      .connectButton2 {
        color: white;
        border: none;
        padding: 0.5dvw 1dvw;
        font-size: 8dvw;
        text-align: center;
        border-radius: 0.25dvw;
        cursor: pointer;
        transition: background-color 0.3s ease;
        opacity: .75;
        font-weight: 600;
        margin-top: 2dvh;
      }

      .watchlistContainer{
        display: block;
        width: 100dvw;
        height: 100dvh;
        padding: 0dvw;
        margin: 0dvw;
      }

      .buttonContainer {
        position: relative;
        width: 100%;
        background-color: rgba(23, 23, 23, 0.7);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .imageContainer {
        margin-top: 10px;
        max-width: 100%;
        max-height: 80%;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }

      .saveImageButton{
        background-color: #333;
        color: white;
        border: none;
        padding: 0.5dvw 1dvw;
        cursor: pointer;
        border-radius: 4.95dvw;
        font-size: 4dvw;
        margin-right: 0dvw;
        margin: 1dvw;
        transition: background-color 0.2s ease;
        z-index: 200;
      }

      .dropdownMenu {
        position: absolute;
        top: 100%;
        right: 0;
        background-color: rgba(31, 31, 31, 0.9);
        border-radius: 0.4dvw;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.7);
       
        z-index: 1001;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 65%;
        min-height: 75dvh;
        place-items: center; /* This centers items both horizontally and vertically */
      }
      
      .dropdownItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgba(60, 60, 60, 0.7);
        border: grey;
        border-style: solid;
        color: white;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s ease;
        border-radius: 1dvw;
        font-size: 4dvw;
        height: 15dvw;
        width: 25dvw;
        padding: 1dvw;
        overflow-y: scroll;
      }
      

   

    
  }