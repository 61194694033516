.mainpage {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
    background: url('./desktopbg.jpg') no-repeat center center fixed, linear-gradient(45deg, #000000, #1a1a1a, #333333);
    background-size: cover;  
}

.codeBlock {
    background-color: #1e1e1e;
    border-radius: 4px;
    margin: 10px 0;
    overflow: hidden;
  }
  
  .codeHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2a2a2a;
    padding: 5px 10px;
  }
  
  .language {
    color: #a9a9a9;
    font-size: 0.9em;
  }
  
  .copyBtn {
    background-color: #3a3a3a;
    color: #ffffff;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 0.9em;
    transition: background-color 0.3s;
  }
  
  .copyBtn:hover {
    background-color: #4a4a4a;
  }
  
  .codeBlock pre {
    margin: 0;
    padding: 10px;
    overflow-x: auto;
  }
  
  .codeBlock code {
    font-family: 'Courier New', Courier, monospace;
    font-size: 0.9em;
    color: #ffffff;
  }

.logo {
    width: 15vw;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
}

.chatContainer {
    width: min(90%, 800px);
    height: 90vh;
    background-color: rgba(26, 26, 26, 0.9);
    border-radius: clamp(10px, 2vw, 20px);
    box-shadow: 0 0 30px rgba(179, 179, 179, 0.3);
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(10px);
    margin: 20px auto;
    position: relative;
    overflow: hidden;
}

.controls {
    display: flex;
    gap: clamp(5px, 1vw, 10px);
    padding: clamp(8px, 1.5vw, 15px);
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: clamp(5px, 1vw, 10px) clamp(5px, 1vw, 10px) 0 0;
    flex-direction: column;
}

.controlButton {
    padding: clamp(6px, 1vw, 12px) clamp(12px, 2vw, 24px);
    border: none;
    border-radius: clamp(4px, 0.8vw, 8px);
    background-color: #333;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: clamp(14px, 1vw, 16px);
    white-space: nowrap;
}

.controlButton:hover {
    background-color: #444;
}

.controlButton.enabled {
    background-color: #4CAF50;
}

.controlButton.enabled:hover {
    background-color: #45a049;
}

.messagesContainer {
    flex: 1;
    overflow-y: auto;
    padding: clamp(10px, 2vw, 20px);
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
    gap: clamp(8px, 1.5vw, 15px);
}

.message {
    padding: clamp(10px, 1.5vw, 15px);
    border-radius: clamp(8px, 1.2vw, 12px);
    max-width: 85%;
    animation: fadeIn 0.5s ease-out;
    font-size: clamp(14px, 1vw, 16px);
    line-height: 1.5;
    min-width: 30%;
}

.message strong {
    display: block;
    margin-bottom: 5px;
    font-size: clamp(14px, 1.1vw, 18px);
}

.user {
    background-color: #2a2a2a;
    align-self: flex-end;
    margin-left: auto;
    color: #fff;
}

.assistant {
    background-color: #1a1a1a;
    align-self: flex-start;
    margin-right: auto;
    color: #fff;
}

.thinking {
    background-color: #2a2a2a;
    color: #888;
    align-self: center;
    text-align: center;
    padding: clamp(8px, 1.2vw, 12px) clamp(16px, 2vw, 24px);
}

.inputContainer {
    display: flex;
    padding: clamp(10px, 2vw, 20px);
    gap: clamp(8px, 1vw, 10px);
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 0 0 clamp(5px, 1vw, 10px) clamp(5px, 1vw, 10px);
}

.messageInput {
    flex-grow: 1;
    padding: clamp(10px, 1.5vw, 15px);
    border: none;
    border-radius: clamp(5px, 0.8vw, 8px);
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    font-size: clamp(14px, 1vw, 16px);
    transition: background-color 0.3s ease;
}

.messageInput:focus {
    outline: none;
    background-color: rgba(255, 255, 255, 0.15);
}

.messageInput::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.sendButton {
    padding: clamp(10px, 1.5vw, 15px) clamp(20px, 2.5vw, 30px);
    border: none;
    border-radius: clamp(5px, 0.8vw, 8px);
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
    font-size: clamp(14px, 1vw, 16px);
    transition: background-color 0.3s ease;
    white-space: nowrap;
}

.sendButton:hover:not(:disabled) {
    background-color: #45a049;
}

.sendButton:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.errorMessage {
    background-color: rgba(255, 0, 0, 0.1);
    color: #ff6b6b;
    padding: clamp(8px, 1.2vw, 12px);
    border-radius: clamp(4px, 0.8vw, 8px);
    margin: clamp(5px, 1vw, 10px) 0;
    text-align: center;
    font-size: clamp(14px, 1vw, 16px);
}

/* Custom scrollbar styles */
.messagesContainer::-webkit-scrollbar {
    width: clamp(4px, 0.6vw, 6px);
}

.messagesContainer::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
}

.messagesContainer::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: clamp(2px, 0.3vw, 3px);
}

.messagesContainer::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.3);
}

@keyframes fadeIn {
    from { 
        opacity: 0; 
        transform: translateY(10px); 
    }
    to { 
        opacity: 1; 
        transform: translateY(0); 
    }
}

/* Mobile-specific styles */
@media (max-width: 768px) {
    .chatContainer {
        width: 100%;
        height: 85vh;
        margin: 0;
        border-radius: 0;
    }

    .logo {
        width: 25vw;
        height: auto;
        position: absolute;
        top: 0;
        left: 0;
    }

    .mainpage {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
    background: url('./mobilebgl.png') no-repeat center center fixed, linear-gradient(45deg, #000000, #1a1a1a, #333333);
    background-size: cover;  
}

    .controls {
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .controlButton {
        padding: 8px 12px;
        font-size: 14px;
    }

    .message {
        max-width: 90%;
        font-size: 16px;
        min-width: 30%;
    }

    .message strong {
        font-size: 16px;
    }

    .inputContainer {
        padding: 10px;
    }

    .messageInput {
        font-size: 16px;
        padding: 12px;
    }

    .sendButton {
        padding: 12px 20px;
        font-size: 16px;
    }
}

/* Small mobile devices */
@media (max-width: 480px) {
    .controls {
        flex-wrap: wrap;
        justify-content: center;
    }

    .message {
        max-width: 95%;
    }

    .inputContainer {
        flex-direction: column;
        gap: 10px;
    }

    .sendButton {
        width: 100%;
    }
}

/* Handle iOS safe areas */
@supports (padding: max(0px)) {
    .chatContainer {
        padding-top: max(0px, env(safe-area-inset-top));
        padding-bottom: max(0px, env(safe-area-inset-bottom));
    }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
    .messageInput::placeholder {
        color: rgba(255, 255, 255, 0.4);
    }
}