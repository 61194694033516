.libraryContainer {
    border-radius: 1dvw;
    /* Matches the central chat panel */
    position: relative;
    height: 85dvh;
    background-color: rgba(23, 23, 23, 0.7);
    backdrop-filter: blur(5px);
    transition: width 0.3s ease;
    z-index: 1000;
    border: 2px solid rgba(255, 255, 255, 0.1);
    padding: 0.5dvw;
    /* Adjust padding if necessary */
    overflow-y: scroll;
    width: 20dvw;
}

.libraryContainer:hover {
    box-shadow: 0 0 40px rgba(179, 179, 179, 0.5);
}

.expanded {
    width: 15dvw;
  
        display: block; /* Or whatever layout you need */
 
}

.collapsed {
    display: none;
}

.libraryContent {
    height: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 0.1dvw;
    font-size: 1dvw;
    overflow-y: auto;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1dvw;
}

.title {
    font-size: 1dvw;
    font-weight: 600;
    color: white;
    margin: 0;
}

.conversationList {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    /* Centers items horizontally */
    width: 100%;
}

.conversationItem {
    width: 100%;
    /* Full width of the container */
    box-sizing: border-box;
    /* Include padding in width */
    background-color: rgba(31, 31, 31, 0.6);
    /* Semi-transparent background */
    border-radius: 6px;
    /* Rounded corners */
    padding: 0.1dvw;
    /* Internal padding */
    margin-bottom: 0.1dvw;
    /* Vertical spacing */
    cursor: pointer;
    /* Hand cursor */
    transition: all 0.2s ease;
    /* Smooth transitions */
    border: 1px solid rgba(255, 255, 255, 0.1);
    /* Subtle border */
    font-size: 0.9dvw;
    /* Font size */
}

.conversationItem:hover {
    background-color: rgba(45, 45, 45, 0.6);
    transform: translateX(-4px);
}

.conversationContent {
    display: flex;
    /* Enable flexbox */
    justify-content: space-between;
    /* Space between name and actions */
    align-items: center;
    /* Vertically center content */
    padding: 0.5dvw;
    /* Internal padding */
}

.conversationName {
    text-align: left;
    /* Align text to the left */
    display: block;
    /* Ensure block behavior */
    margin-right: 0.25dvw;
    /* Small margin for spacing */
}

.conversationActions {
    margin-left: auto;
    /* Push to the right */
    display: flex;
    /* Enable flexbox for actions */
    gap: 0.5rem;
    /* Spacing between action elements */

}

.menuButton {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: white;
}

.menuDropdown {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    background-color: #333;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    z-index: 100;
    display: flex;
    justify-content: space-between;
    width: fit-content;
    align-items: center;
    height: fit-content;
}

.menuDropdown button {
    padding: 10px;
    background: none;
    border: none;
    color: white;
}

.renameInput {
    display: flex;
    padding: 0.5rem;
    gap: 0.5rem;
}

.renameInput input {
    flex: 1;
    background-color: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    padding: 0.4rem;
    color: white;
}

.renameInput input:focus {
    outline: none;
    border-color: rgba(255, 255, 255, 0.4);
}

.renameInput button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
}

.renameInput button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.confirmOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.confirmDialog {
    background-color: rgba(51, 51, 51, 0.95);
    padding: 1.5rem;
    border-radius: 8px;
    max-width: 300px;
    width: 90%;
    text-align: center;
}

.confirmDialog p {
    margin-bottom: 1.5rem;
    color: white;
}

.confirmButtons {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.confirmButton {
    padding: 0.5rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s ease;
    background-color: #dc3545;
    color: white;
}

.confirmButton:hover {
    background-color: #c82333;
}

.cancelButton {
    padding: 0.5rem 1.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s ease;
    background-color: #6c757d;
    color: white;
}

.cancelButton:hover {
    background-color: #5a6268;
}

.agentOutputsSection {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.outputModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.outputModalContent {
    background-color: rgba(51, 51, 51, 0.95);
    padding: 1.5rem;
    border-radius: 8px;
    max-width: 80%;
    width: 90%;
    text-align: center;
}

.closeButton {
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
}

.closeButton:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.mobileOnly {
    display: none;
}

.outputText {
    color: white;
    margin-top: 1rem;
}

.mobileHeader {
    display: none;
}

@media (max-width: 768px) {
    .libraryContainer {
        width: 100dvw;
        height: 100dvh;
        position: fixed;
        bottom: 0;
        left: 0;
        overflow-y: auto;
        border-radius: 0;
    }

    .conversationcno .mobileHeader {
        display: none;
    }

    .menuButton {
        font-size: 2rem;
    }

    .conversationItem {
        padding: 15px;
        font-size: 18px;
        z-index: 1;
    }

    .conversationContent {
        display: flex;
        /* Enable flexbox */
        justify-content: space-between;
        /* Space between name and actions */
        align-items: center;
        /* Vertically center content */
        padding: 0.5dvw;
        /* Internal padding */
        z-index: 1;
    }

    .title {
        display: flex;
        align-items: center;
        padding: 10px;
        background-color: #1a1a1a;
        font-size: 5dvw;
    }

    .mobileOnly {
        display: flex;
        background-color: rgba(255, 255, 255, 0.05);  color: white;
        padding: 6px 12px;
        border-radius: 2dvw;
        margin-top: 1dvw;
        border: none;
        cursor: pointer;
        transition: background-color 0.2s ease;
        width: 30dvw;
        text-align: center;
        justify-content: center;
        margin-bottom: 1dvw;
      
    }

    .header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 1dvw;
      
    }
}