/* OrdinalsWalletPage.css */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

body {
    background-color: black;
    color: white;
    font-family: Arial, sans-serif;
}

.mt-2 {
    font-size: 1.5dvw;
    color: white;
}

.wallet-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .disconnect-button {
    background-color: white;
    color: black;
    border: none;
    padding: 7px;
    font-size: 1.25dvw;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 20px;
    margin-top: 1dvw;
    font-weight: 600;
    display: block;  /* Makes the button behave like a block element */
    margin-left: auto;
    margin-right: auto; /* Centers it horizontally */
    opacity: .75;
}
  
  .disconnect-button:hover {
    background-color: grey;
  }
  
  .wallet-modal {
    background-color: rgb(72, 72, 72);
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    opacity: .9;
  }
  
  .wallet-modal h2 {
    margin: auto;
    text-align: center;
    margin-bottom: 1dvw;
    font-size: 1.5dvw;
  }
  
  .wallet-modal button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.5dvw;
    font-weight: 600;
  }
  
  .wallet-modal button:hover {
    background-color: #e0e0e0;
  }

  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
  }
  
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #ffffff;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loading-container p {
    margin-top: 20px;
    font-size: 18px;
    color: #ffffff;
  }

.ordinals-wallet-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: 100dvh;
    min-height: 100dvh;
    max-height: 100dvh;
    overflow: scroll;
    width: 100dvw;
    min-width: 100dvw;
    background-image: url('/public/bg.png');
    background-size: cover;
}

.argohomebutton {
    background-color: white;
    color: black;
    border: none;
    padding: 7px;
    font-size: 1.25dvw;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 20px;
    position: fixed;
    top: 1%;
    left: 1%;
    font-weight: 600;
    display: block;  /* Makes the button behave like a block element */
    margin-left: auto;
    margin-right: auto; /* Centers it horizontally */
    text-decoration: none;
    opacity: .75;
  }

  .argotitle {
    font-size: 2.5dvw;
    font-weight: bold;
    margin-bottom: .75dvh;
    text-align: center;
    flex-grow: 1;
  }

  .argotitlesection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

.text-2xl {
    margin-bottom: 20px;
    text-align: center;
}

.mb-2 {
    margin-bottom: 1dvh;
    width: 100%;
    text-align: center;
    word-break: break-all;
    font-size: 1dvw;
}

.mb-7 {
    margin-bottom: 20px;
    width: 100%;
    text-align: left;
    word-break: break-all;
    font-size: 2dvw;
}

.connect-button {
    background-color: white;
    color: black;
    border: none;
    padding: 10px 20px;
    font-size: 2dvw;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    opacity: .75;
    font-weight: 600;
    margin-top: 2dvw;
}

.connect-button:hover {
    background-color: #e0e0e0;
}



.gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-top: 20px;
    width: 100%;
}

.nft-item {
    text-align: center;
}

.nft-item img {
    width: 17dvw;
    height: auto;
    cursor: pointer;
    object-fit: cover;
}

.modal {
    display: flex;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100dvw;
    height: 100dvh;
    background-color: rgba(0, 0, 0, 0.8);
    align-items: center;
    justify-content: center;
}

.modal-content {
    background-color: #121212;
    width: fit-content;
    height: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    padding: 3dvw;
    color: white;
    justify-content: center;
}

.close {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 30px;
    color: #ffffff;
    cursor: pointer;
    z-index: 2;
}

.nft-name {
    position: relative;
    padding: 10px;
    border-radius: 5px;
    font-size: 24px;
    font-weight: bold;
    color: white;
    z-index: 1;
    margin-top: 20px;
}

.nft-details {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.nft-content-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
}

.nft-image-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-right: 20px;
    flex-direction: column;
}

.nft-image {
    width: 100%;
    height: 100%;
}

.nft-traits {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
}

.nft-traits h3 {
    font-size: 20px;
    margin-bottom: 15px;
    color: white;
}

.traits-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    margin-bottom: 20px;
}

.trait {
    background-color: #2a2a2a;
    padding: 10px;
    border-radius: 5px;
    color: white;
}

.inscription-info {
    text-align: center;
    margin-top: 20px;
}

.inscription-info p {
    color: #cccccc;
    margin-bottom: 10px;
}

.ordiscan-button {
    display: inline-block;
    background-color: #0077cc;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.ordiscan-button:hover {
    background-color: #005fa3;
}





@media (max-width: 767px) {
    .gallery {
        grid-template-columns: repeat(2, 1fr);
    }

    .modal-content {
        width: 90%;
        height: 90%;
        overflow-y: auto;
        padding: 40px 20px 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .ordinals-wallet-page {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        height: 100dvh;
        overflow: scroll;
        width: 100dvw;
        background-image: url('/public/bg2.png');
        background-size: cover;
    }

    .close {
        top: 10px;
        right: 10px;
        font-size: 36px;
        z-index: 3;
    }

    .nft-details {
        flex-direction: column;
        margin-top: 0;
        height: auto;
    }

    .nft-content-wrapper {
        flex-direction: column;
        align-items: center;
    }

    .nft-image-container {
        padding-right: 0;
        margin-bottom: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .nft-image {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 40vh;
        object-fit: contain;
        margin-top: 20px;
    }

    .nft-item img {
        width: 35dvw;
        height: auto;
        cursor: pointer;
        object-fit: cover;
    }

    .connect-button {
        background-color: white;
        color: black;
        border: none;
        padding: 10px 20px;
        font-size: 16px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }


    .disconnect-button {
        background-color: white;
        color: black;
        border: none;
        padding: 7px;
        font-size: 12px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        margin-bottom: 20px;
        margin-top: 3dvw;
        font-weight: 600;
        display: block;  /* Makes the button behave like a block element */
        margin-left: auto;
        margin-right: auto; /* Centers it horizontally */
    }

    .nft-name {
        position: static;
        font-size: 24px;
        margin: 0px 0 0px;
        text-align: center;
        background-color: transparent;
        width: 100%;
    }

    .nft-traits {
        padding-left: 0;
        width: 100%;
    }

    .traits-grid {
        grid-template-columns: 1fr;
    }

    .inscription-info p {
        display: none;
    }

    .text-2xl {
        margin-bottom: 20px;
        text-align: center;
        font-size: 2rem;
    }

    .mb-2 {
        font-size: 3dvw;
        margin-bottom: 0dvw;
    }

      .argotitle {
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        flex-grow: 1;
        margin-top: 8dvw;
      }
    
      .argotitlesection {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-bottom: 0rem;
      }

      .argohomebutton {
        background-color: white;
        color: black;
        border: none;
        padding: 7px;
        font-size: 3dvw;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        margin-bottom: 20px;
        position: absolute;
        font-weight: 600;
        display: block;  /* Makes the button behave like a block element */
        margin-left: auto;
        margin-right: auto; /* Centers it horizontally */
        text-decoration: none;
      }

      .mb-7 {
        margin-bottom: 20px;
        width: 100%;
        text-align: center;
        word-break: break-all;
        font-size: 5dvw;
        font-weight: 600;
    }

    .mt-2 {
        font-size: 4dvw;
        color: white;
    }

    .senderoni {
        width: 100%;
    }

    .wallet-modal button {
        display: block;
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        background-color: #f0f0f0;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 4dvw;
        font-weight: 600;
      }

      .wallet-modal h2 {
        margin: auto;
        text-align: center;
        margin-bottom: 3dvw;
        font-size: 5dvw;
      }
}