.homepage {
  width: 100dvw;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100dvh;
}

.argoailogo {
  width: 30dvw;
  height: auto;
}

.argoailogo2 {
  width: 75dvw;
  height: auto;
}

.imageContainer{
  margin-top: 0px;
  display: flex;
  margin-bottom: 0px;
  
}

.landingContainer2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url('./desktopbg.jpg') no-repeat center center fixed, linear-gradient(45deg, #000000, #1a1a1a, #333333);
  background-size: cover;
  text-align: center;
  padding: 1dvw;
  height: 100dvh;
  width: 100dvw;
}


.landingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('./desktopbg.jpg') no-repeat center center fixed, linear-gradient(45deg, #000000, #1a1a1a, #333333);
  background-size: cover;
  text-align: center;
  padding: 1dvw;
  height: 100dvh;
  width: 100dvw;
}


.title {
  font-size: 3dvw;
  margin-bottom: 2dvh;
  margin-top: 0dvh;
  color: white;
}

.title2 {
  font-size: 5dvw;
  margin-bottom: 2dvh;
  margin-top: 0dvh;
  color: white;
}

.buttonContainer {
  display: flex;
  gap: 2dvw;
  margin-top: 2dvh;
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1dvw;
  background-color: #b7b7b7;
  color: #000000;
  text-decoration: none;
  border-radius: .1dvw;
  font-size: 2dvw;
  transition: background-color 0.3s ease;
  font-weight: 600;
}

.button:hover {
  background-color: #357ab8;
}

.buttonText {
  font-weight: bold;
}

.tokenGated {
  font-size: 1dvw;
  margin-top: .1dvw;
  color: black;
}

.projectInfoRect {
  width: 23%;
  /* Adjust the width to your liking */
  height: 5.5%;
  /* Adjust the height to your liking */
  top: 38%;
  /* Use percentages for positioning */
  left: 57%;
  /* Adjust this value for horizontal placement */

}


.mobilehomepage {
  display: none;
  width: 100dvw;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.mobileImage {
  width: 100%;
  height: 100%;
}

.mapOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mapLink {
  position: absolute;
  cursor: pointer;
  pointer-events: auto;
  opacity: 0.5;
  /* Make the backgrounds semi-transparent */
}

.mediumLink {
  left: 30%;
  top: 44.5%;
  width: 30%;
  height: 6%;

}

.argod {
  left: 20%;
  top: 60%;
  width: 65%;
  height: 20%;

}

.twitterLink {
  left: 22%;
  top: 38%;
  width: 32%;
  height: 5.5%;

}

.discordLink {
  left: 32%;
  top: 32%;
  width: 35%;
  height: 5.5%;
}

.edenLink {
  left: 40%;
  top: 82%;
  width: 25%;
  height: 12%;
}

.nextWeekLink {
  left: 30%;
  top: 53%;
  width: 37%;
  height: 7%;

}

.comingSoonLink {
  left: 12%;
  top: 82%;
  width: 25%;
  height: 12%;
}

.okxLink {
  left: 67%;
  top: 82%;
  width: 22%;
  height: 12%;
}

.comingSoonLink2 {
  left: 24%;
  top: 4%;
  width: 50%;
  height: 25%;

}

.gallerylink1 {
  left: 27%;
  top: 51%;
  width: 26%;
  height: 5.5%;

}

.holderslink1 {
  left: 57%;
  top: 51%;
  width: 25%;
  height: 5.5%;

}

.checkerlink {
  left: 30%;
  top: 30%;
  width: 40%;
  height: 15%;
}

.popupContent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 2px solid black;
  border-radius: 8px;
  z-index: 1000;
  width: 80%;
  /* Increase the width to 80% of the screen */
  max-width: 400px;
  /* Set a maximum width for larger screens */
  box-sizing: border-box;
  /* Include padding in the width calculation */
}

.popupContent p {
  font-size: 1.5rem;
  /* Slightly smaller font size for better fit */
  font-weight: bold;
  text-align: center;
  margin: 0;
  line-height: 1.4;
  /* Improve line spacing for readability */
}

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}



@media (max-width: 768px) {
  .homepage {
    display: none;
  }

  .mobilehomepage {
    display: block;
  }

  .argoailogo2 {
    width: 75dvw;
    height: auto;
  }

  .argoailogo {
    width: 100dvw;
    height: auto;
    margin-bottom: 0dvw;
  }

  .title {
    font-size: 8dvw;
    margin-top: 0dvw;
  }
  
  .buttonContainer {
    display: flex;
    gap: 10dvw;
    margin-top: 2dvh;
    flex-direction: column;

  }
  
  .button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2dvw;
    background-color: rgba(68, 68, 68, 0.688);
    color: white;
    text-decoration: none;
    border-radius: 2dvw;
    font-size: 8dvw;
    transition: background-color 0.3s ease;
    font-weight: 600;
  }
  
  .button:hover {
    background-color: #357ab8;
  }
  
  .buttonText {
    font-weight: bold;
    font-size: 6dvw;
  }
  
  .tokenGated {
    font-size: 5dvw;
    margin-top: .1dvw;
    color: white;
  }

  .landingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url('../components/mobilebgl.png') no-repeat center center fixed, linear-gradient(45deg, #000000, #1a1a1a, #333333);
    background-size: cover;
    text-align: center;
    padding: 1dvw;
    height: 100dvh;
    width: 100dvw;
  }
}