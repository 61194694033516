.container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1a1a1a;
    padding: 20px;
  }
  
  .card {
    background-color: #2a2a2a;
    border-radius: 12px;
    padding: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 100%;
  }
  
  .logo {
    width: 120px;
    height: auto;
    margin-bottom: 20px;
  }
  
  .loadingContainer,
  .errorContainer,
  .successContainer {
    margin-top: 20px;
  }
  
  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(255, 255, 255, 0.1);
    border-left-color: #ffffff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 0 auto 20px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .button {
    background-color: #4a4a4a;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-top: 20px;
  }
  
  .button:hover {
    background-color: #5a5a5a;
  }
  
  .errorText {
    color: #ff4444;
    margin-bottom: 20px;
  }
  
  h2 {
    color: #ffffff;
    margin-bottom: 16px;
  }
  
  p {
    color: #cccccc;
    margin-bottom: 12px;
    line-height: 1.5;
  }